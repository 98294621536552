<template>
    <div class="CaseShow">
        <!-- <h1>项目案例</h1> -->
        <img src="https://img.youstarplanet.com/website/caseShow/CaseShow.png" alt="">
        <div class="caseShow_content">
            <div>
                <div>六大产品线</div>
                <div></div>
                <img src="https://img.youstarplanet.com/website/caseShow/CaseShow1.png" alt="">
                <div @click="detailsJump1">
                    <img src="https://img.youstarplanet.com/website/youjiantou.svg" alt="">
                    <span>查看详情</span>
                </div>
            </div>
            <div>
                <div>“果冻星球”音乐节</div>
                <div></div>
                <img src="https://img.youstarplanet.com/website/caseShow/CaseShow2.png" alt="">
                <div @click="detailsJump2">
                    <img src="https://img.youstarplanet.com/website/youjiantou.svg" alt="">
                    <span>查看详情</span>
                </div>
            </div>
            <div>
                <div>各品类演艺资源</div>
                <div></div>
                <img src="https://img.youstarplanet.com/website/caseShow/CaseShow3.png" alt="">
                <div @click="detailsJump3">
                    <img src="https://img.youstarplanet.com/website/youjiantou.svg" alt="">
                    <span>查看详情</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return {}
    },
    methods: {
        detailsJump1(){
            this.$router.push({path:`/products`})
        },
        detailsJump2(){
            this.$router.push({path:`/jellyplanet`})
        },
        detailsJump3(){
            this.$router.push({path:`/resources`})
        },
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.CaseShow{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .caseShow_content{
        width: 1270px;
        margin: 0 auto;
        &>div{
            cursor:pointer;
            &:nth-child(1){
                margin-top: 76px;
                margin-bottom: 210px;
                display: flex;
                flex-direction: column;
                align-items: center;
                div{
                    &:nth-child(1){
                        font-size: 28px;
                    }
                    &:nth-child(2){
                        width: 40px;
                        height: 7px;
                        background-color: #D00A13;
                        margin-top: 30px;
                        margin-bottom: 37px;
                    }
                    &:nth-child(4){
                        width: 124px;
                        height: 40px;
                        line-height: 40px;
                        background-color: #E3E3E3;
                        border-radius: 21px;
                        display: flex;
                        align-items: center;
                        img{
                            width: 14px;
                            height: 14px;
                            margin-left: 18px;
                            margin-right: 12px;
                        }
                        span{
                            font-size: 14px;
                            color: #FFFFFF;
                        }
                    }
                }
                img{
                    width: 600px;
                    height: 120px;
                }
            }
            &:nth-child(2){
                margin-bottom: 210px;
                display: flex;
                flex-direction: column;
                align-items: center;
                div{
                    &:nth-child(1){
                        font-size: 28px;
                    }
                    &:nth-child(2){
                        width: 40px;
                        height: 7px;
                        background-color: #D00A13;
                        margin-top: 30px;
                        margin-bottom: 37px;
                    }
                    &:nth-child(4){
                        width: 124px;
                        height: 40px;
                        line-height: 40px;
                        background-color: #E3E3E3;
                        border-radius: 21px;
                        display: flex;
                        align-items: center;
                        img{
                            width: 14px;
                            height: 14px;
                            margin-left: 18px;
                            margin-right: 12px;
                        }
                        span{
                            font-size: 14px;
                            color: #FFFFFF;
                        }
                    }
                }
                img{
                    width: 100%;
                    height: 153px;
                }
            }
            &:nth-child(3){
                margin-bottom: 200px;
                display: flex;
                flex-direction: column;
                align-items: center;
                div{
                    &:nth-child(1){
                        font-size: 28px;
                    }
                    &:nth-child(2){
                        width: 40px;
                        height: 7px;
                        background-color: #D00A13;
                        margin-top: 30px;
                        margin-bottom: 37px;
                    }
                    &:nth-child(4){
                        width: 124px;
                        height: 40px;
                        line-height: 40px;
                        background-color: #E3E3E3;
                        border-radius: 21px;
                        display: flex;
                        align-items: center;
                        img{
                            width: 14px;
                            height: 14px;
                            margin-left: 18px;
                            margin-right: 12px;
                        }
                        span{
                            font-size: 14px;
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}
</style>